import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import type { ParsedQs } from 'qs';
import { type FC, useCallback, useState } from 'react';

import useAuth from '@/hooks/use-auth.ts';
import { apiRequest } from '@/services/fetcher.ts';

interface OwnProps {
  open: boolean;
  onSent: () => void;
  onCancel: () => void;
  filters?: ParsedQs;
  isFormDisabled?: boolean;
}

interface Values {
  message: string;
}

const BulkMessageForm: FC<OwnProps> = ({ open, onCancel, onSent, filters, isFormDisabled }) => {
  const auth = useAuth();
  const [form] = useForm<Values>();
  const [isQueueing, setIsQueueing] = useState(false);

  const queueMessageSending = useCallback(
    async (message: string, filters?: ParsedQs) => {
      await apiRequest(`${import.meta.env.VITE_API_URL}/manage/telegram-group/message`, auth, {
        method: 'post',
        body: JSON.stringify({
          message,
          filter: filters,
        }),
      });
    },
    [auth],
  );

  const onTriggerClose = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const onSubmit = useCallback(async () => {
    setIsQueueing(true);
    const values = await form.validateFields();
    await queueMessageSending(values.message, filters);
    setIsQueueing(false);
    form.resetFields();
    onSent();
  }, [form, filters, queueMessageSending, onSent]);

  return (
    <Modal
      title="Send bulk message"
      open={open}
      onCancel={onTriggerClose}
      okText="Send"
      onOk={() => form.submit()}
      okButtonProps={{ disabled: isFormDisabled === true }}
      confirmLoading={isQueueing}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="message"
          extra="Message will be sent to filtered conversations."
          rules={[
            {
              required: true,
              message: 'Please input the message!',
            },
            {
              max: 4096,
              message: 'Message can not exceed 4096 characters.',
            },
          ]}
        >
          <Input.TextArea showCount maxLength={4096} rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BulkMessageForm;
